import React from "react";
import { getCurrency } from "country-currency-map";
import styled from "styled-components";
import getSymbolFromCurrency from "currency-symbol-map";
import { countries } from "countries-list";
import { sortCurrencyObjectByNameFunction } from "./format";
import { addCommasToNumber } from "../pages/Cards/utils";

const SelectedDropdownOption = styled.div`
  display: flex;
  align-items: center;

  & > li {
    margin-right: 10px;
  }
`;

export const getCurrencyOptions = (currencyList) => {
  return currencyList.map((curr) => {
    let theFullName;
    if (curr === "GHS") {
      theFullName = "Ghana Cedis";
    } else if (curr === "ZMW") {
      theFullName = "Zambian Kwacha";
    } else {
      const regex = /\((.*?)\)/i;
      theFullName = getCurrency(curr)?.name;
      if (theFullName) {
        theFullName = theFullName
          .replace(regex, "")
          .trimEnd();
      }
    }
    return {
      value: curr,
      fullName: theFullName,
      label: (
        <SelectedDropdownOption>
          <li
            className={`currency-flag currency-flag-${curr?.toLowerCase()}`}
          />{" "}
          {curr}
        </SelectedDropdownOption>
      ),
    };
  });
};

export const currencyList = [
  { label: "AUD", value: "AUD" },
  { label: "CAD", value: "CAD" },
  { label: "CNY", value: "CNY" },
  { label: "GBP", value: "GBP" },
  { label: "JPY", value: "JPY" },
  { label: "KES", value: "KES" },
  { label: "NGN", value: "NGN" },
  { label: "USD", value: "USD" },
  { label: "INR", value: "INR" },
  { label: "TZS", value: "TZS" },
];

export const allCurrenciesList = () => {
  const curListSet: any = new Set();

  for (const [key, value] of Object.entries(countries)) {
    if (key === "AQ") continue;
    const currencies = value.currency.split(",");

    curListSet.add(currencies[0]);
  }

  const curList = [...curListSet];
  const currOptions = curList.map((cur) => ({
    label: cur,
    value: cur,
  }));

  const sortedCurr = [...currOptions].sort(
    sortCurrencyObjectByNameFunction,
  );

  return sortedCurr;
};

export const formatCurrencyAmount = (
    currencyCode: string,
    amount: string | number,
    decimalPlaces: number = 2
  ) => {
    const intAmount = Number(amount);
      if (isNaN(intAmount)) {
      throw new Error("Invalid amount provided");
    }
      const floatAmount = intAmount.toFixed(decimalPlaces);
      const formattedAmount = addCommasToNumber(floatAmount);
      const currSymbol = getSymbolFromCurrency(currencyCode);
      if (currSymbol) {
      return `${currSymbol}${formattedAmount}`;
    }
    return `${currencyCode}${formattedAmount}`;
  };


export const formatCurrencyCodeAmount = (
    currencyCode: string,
    amount: number | string,
) => {
    const intAmount = +amount;
    const amountWithDecimals = intAmount.toFixed(2);

    const formattedAmount = parseFloat(amountWithDecimals).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return `${currencyCode} ${formattedAmount}`;
};


export const formatNumber = (number) => {
  let strNumber = String(number);
  let parts = strNumber.split(".");
  let integerPart = parts[0]
    .split("")
    .reverse()
    .map((digit, index) =>
      index > 0 && index % 3 === 0 ? `${digit},` : digit,
    )
    .reverse()
    .join("");

  let decimalPart = parts[1] ? parts[1] : "00";
  decimalPart = decimalPart.padEnd(2, "0");
  let formattedNumber = `${integerPart}.${decimalPart}`;

  return formattedNumber;
};


export const formatKwireAmount = (
    currencyCode: string,
    amount: string | number,
  ) => {
    const currSymbol = getSymbolFromCurrency(currencyCode);

    const intAmount = +amount;
    const floatAmount = currencyCode === 'USD' ? intAmount.toFixed(2) : intAmount.toFixed(6);

    const formattedAmount = addCommasToNumber(floatAmount);

    if (currSymbol) {
      return `${currSymbol}${formattedAmount}`;
    }
    return `${currencyCode}${formattedAmount}`;
  };

  export const formatNewKwireAmount = (
    currencyCode: string,
    amount: string | number,
) => {
  const currSymbol = getSymbolFromCurrency(currencyCode);
  if (currSymbol) {
    return `${currSymbol}${amount}`;
  }
  return `${currencyCode}${amount}`;
};
