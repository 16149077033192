import styled from "styled-components";
import CustomSelect from "../../../../../../../../components/common/CustomSelect";
import { motion } from "framer-motion";

export const IntlCurrency  = styled(motion.div)`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;

  svg {
    margin: 40px 0;;
  }

  h1 {
    color: #000000;
    font-family:"Athletics";
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.48px;
    text-align: center;
  }

  @media (min-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

export const ChargesDisplay = styled.div`
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
`;

export const Message = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.03em;
  color: #565c69;
`;
export const Amount = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;

export const Label = styled.span`
  font-size: 12px;
  line-height: 24px;
  color: #767676;
`;

export const Wrapper = styled.div`
  width: 740px;
  margin: auto;
  overflow-y: scroll;
  height: 70vh;
`;

export const StepContent = styled.section`
  width: 80%;
  margin: auto;
`;

export const Title = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
`;

export const CurrentStep = styled.div`
  margin: 40px 0 10px;

  & > * {
    width: 100%;
  }
`;

interface Props {
  activeCurrency: string;
}

export const CurrencyTypeWrapper = styled.div<Props>`
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
width: 90%;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 200px;
    text-align: center;
    width: 100%;
    position: relative;
    top: 18px;
    cursor: pointer;
    padding-bottom: 12px;
    
    &.active {
      color: #000000;
      border-bottom: 2px solid black;
      position: relative;
      top: 18px;
    }

    &.inactive {
      color: #565c69;
    }
  }

  @media (min-width: 768px) {
    gap: 64px;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto 20px;
  }
`;

export const Text = styled.span`
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px; 
  margin: 0;
`;

export const RateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  border-radius: 8px;
  background-color: #F9F9F9;
`;

export const Form = styled.div`
  width: 90%;
  margin: 0 auto;

  .min{
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 8px;

    span {
      font-size: 10px;
      color: var(--primary-pink);
    }
    
    a {
      font-size: 10px;
      text-decoration: underline;
      color: #000000;
    }
  } 
  
  @media (min-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

export const FormLabel = styled.span`
  margin: 20px 0;
  color: #565c69;
  font-size: 12px;
`;

export const SelectCountry = styled(CustomSelect)`
  height: 48px;
  width: 97%;
  position: relative;
  left: 6px;
  margin: auto;
  display: flex;
  justify-content: center;
  // margin-bottom: 37px;
`;

export const Section = styled.div`
  margin-bottom: 25px;
  font-family: "Inter";
  
  & > p {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 180%;
    color: #000000;
  }

  .debit {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const ContinueButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;

  span {
    color: #565c69;
    margin-bottom: 5px;
    font-size:12px;
  }
`;

export const FeeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DebitWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  padding: 6px 0px;
  align-items: center;
  gap: 16px;

  & > :first-child {
    position: relative;
    bottom: 10px;
  }
`;

export const UpgradeWrapper = styled.div`
    margin-top: 70px;
`;