import React, { useState, useRef, useEffect } from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';

interface ResponsivePopoverProps {
  content: React.ReactNode;
  children: React.ReactNode;
  [key: string]: any; 
}

const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    max-width: calc(100vw - 32px);
    width: auto;
    margin-left: 1rem;
    box-sizing: border-box;
  }

  .ant-popover-arrow {
    display: none !important; 
  }
`;

const ResponsivePopover: React.FC<ResponsivePopoverProps> = ({ content, children, ...props }) => {
  const [popoverStyle, setPopoverStyle] = useState({});
  const triggerRef = useRef<HTMLDivElement>(null);

  const updatePopoverStyle = () => {
    if (triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const isMobile = viewportWidth <= 768;

      let left = triggerRect.left;
      let top = triggerRect.bottom + window.scrollY;

      const maxWidth = Math.min(400, viewportWidth - 32); 
      if (left + maxWidth > viewportWidth - 16) {
        left = Math.max(16, viewportWidth - maxWidth - 16);
      }

      const estimatedHeight = 300; 
      if (top + estimatedHeight > viewportHeight) {
        top = Math.max(16, triggerRect.top - estimatedHeight + window.scrollY);
      }

      setPopoverStyle({
        position: 'absolute',
        top: `${top}px`,
        left: `${left}px`,
        maxWidth: `${maxWidth}px`,
        width: isMobile ? `${maxWidth}px` : 'auto',
      });
    }
  };

  useEffect(() => {
    updatePopoverStyle();
    window.addEventListener('resize', updatePopoverStyle);
    window.addEventListener('scroll', updatePopoverStyle);
    return () => {
      window.removeEventListener('resize', updatePopoverStyle);
      window.removeEventListener('scroll', updatePopoverStyle);
    };
  }, []);

  return (
    <StyledPopover
      content={content}
      overlayStyle={popoverStyle}
      getPopupContainer={() => document.body}
      {...props}
    >
      <div ref={triggerRef}>{children}</div>
    </StyledPopover>
  );
};

export default ResponsivePopover;