import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Wrapper, ModalTitle, ModalSubtitle, HeaderWrapper, BodyWrapper, ButtonContainer } from "./style";
import { Input } from "../../common/Input";
import { Button } from "../../common/Button";
import CustomRadio from "../../common/RadioInput";
import CustomDatePicker from "../../common/CustomDateRangePicker/CustomDatePicker";
import { formatDate } from "../../../utils/dateUtils";

type Props = {
    exportByFilterFunction: (filterParams: any) => void;
    hasDowndloadPermission: any;
    role: any;
}

const ExportByFilterModal: React.FC<Props> = ({exportByFilterFunction, hasDowndloadPermission, role}) => {
    const { control, handleSubmit, watch, setValue, formState: { isValid } } = useForm({
        mode: "onChange",
        defaultValues: {
            startDate: "",
            endDate: "",
            transactionType: "",
            fileFormat: ""
        }
    });

    const onSubmit = (data) => {
        const formattedData = {
            ...data,
            startDate: data.startDate ? formatDate(data.startDate) : "",
            endDate: data.endDate ? formatDate(data.endDate) : ""
        };
        console.log(formattedData);
        exportByFilterFunction(formattedData)
        
    };

    const watchAllFields = watch();

    const handleRadioChange = (name, value) => {
        setValue(name, watchAllFields[name] === value ? "" : value, { shouldValidate: true });
    };

    return (
        <Wrapper>
            <HeaderWrapper>
                <ModalTitle>
                    Get collection statement
                </ModalTitle>
                <ModalSubtitle>
                    Your collection statement will be sent to your email address.
                </ModalSubtitle>
            </HeaderWrapper>

            <form onSubmit={handleSubmit(onSubmit)}>
                <BodyWrapper>
                    <Controller
                        name="startDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <CustomDatePicker
                                value={field.value}
                                onChange={(date) => field.onChange(date)}
                                placeholder="Start date"
                            />
                        )}
                    />
                    <Controller
                        name="endDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <CustomDatePicker
                                value={field.value}
                                onChange={(date) => field.onChange(date)}
                                placeholder="End date"
                            />
                        )}
                    />

                    <section>
                        <h2>Transaction Type</h2>
                        <div>
                            <Controller
                                name="transactionType"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <>
                                        <CustomRadio
                                            label="Collections"
                                            value="collections"
                                            onClick={() => handleRadioChange("transactionType", "collections")}
                                            selected={field.value === "collections"}
                                        />
                                        <CustomRadio
                                            label="Payout"
                                            value="payout"
                                            onClick={() => handleRadioChange("transactionType", "payout")}
                                            selected={field.value === "payout"}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    </section>

                    <section>
                        <h2>Preferred file format</h2>
                        <div>
                            <Controller
                                name="fileFormat"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <>
                                        <CustomRadio
                                            label=".CSV file format"
                                            value="csvFile"
                                            onClick={() => handleRadioChange("fileFormat", "csvFile")}
                                            selected={field.value === "csvFile"}
                                        />
                                        <CustomRadio
                                            label=".XLS file format"
                                            value="xlsFile"
                                            onClick={() => handleRadioChange("fileFormat", "xlsFile")}
                                            selected={field.value === "xlsFile"}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    </section>
                </BodyWrapper>

                <ButtonContainer>
                    <Button
                        label="Submit request"
                        type="submit"
                        disabled={!isValid}
                        height="40px"
                        fontSize="12px"
                    />
                </ButtonContainer>
            </form>
        </Wrapper>
    );
};

export default ExportByFilterModal;