import React, {useEffect, useState} from "react";
import {useWindowSize} from "@reach/window-size";
import ToggleButton from "../../../../../../components/common/ToggleButton/ToggleButton";
import CustomTable from "../../../../../../components/common/CustomTable";
import {useParams} from "react-router-dom";
import {
    StyledTransactionsTable,
    ToggleContainer,
} from "../../../../components/TransactionsTable/style";
import {
    capitalizeName,
    eportWalletTransaction,
    exporWalletXlX,
    extractedString,
    formatValue,
    payIntableColumns,
    tableColumns,
} from "../../../../utils";
import {useAppSelector} from "../../../../../../app/hooks";
import dayjs from "dayjs";
import Loader from "../../../../../../components/common/Loader";
import Tools from "../../../../components/TransactionsTable/components/Tools";
import {
    getPayoutTransactions,
    getPayInTransactions,
} from "../../../../api";
import {formatLongString} from "../../../../../../utils/formatString";
import {
    formatCurrencyAmount,
    formatCurrencyCodeAmount,
} from "../../../../../../utils/currency";
import {
    EmptyWrapper,
    EmptyWrapperTitle,
    EmptyWrapperSubTitle,
} from "../../../../../Balances/style";
import {MobileBody} from "../../../../../Transactions/style";
import EmptyMobileCard from "../../../../../../components/emptyStates/EmptyMobileCard";
import WalletCard from "../../../../components/WalletCard";
import {
    ReactComponent as EmptyTableIcon
} from "../../../../../../components/common/CustomTable/assets/no-transactions.svg";
import Drawer from "../Drawer/Drawer";
import CustomMobileTable from "../../../../../../components/common/CustomMobileTable";

const EmptyBalanceDescription = () => {
    return (
        <EmptyWrapper>
            <EmptyWrapperTitle>
                No transactions yet
            </EmptyWrapperTitle>
            <EmptyWrapperSubTitle>
                All received or sent transactions will
                appear here
            </EmptyWrapperSubTitle>
        </EmptyWrapper>
    );
};

interface DateProps {
    startDate: Date;
    endDate: Date;
}

const Table = () => {
    const {width} = useWindowSize();
    const {businessId} = useAppSelector(
        (state) => state.users,
    );

    const {wallets} = useAppSelector(
        (state) => state.wallets,
    );
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        openDrawer: false,
        data: null,
    });

    const [tableData, setTableData] = useState(null);
    const [debitData, setDebitData] = useState(null);
    const [creditData, setCreditData] = useState(null);
    const [totalPayInData, setTotalPayinData] =
        useState(null);
    const {currencyCode} = useParams();
    const [activeToggle, setActiveToggle] =
        useState("Credit");
    const toggleType = ["Debit", "Credit"];
    const [select, setSelect] = useState(null);
    const [status, setStatus] = useState<number>();
    const [date, setDate] = useState<DateProps>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState("");

    const currentWallet = wallets?.find(
        (wallet) => wallet?.currency === currencyCode,
    );

    if (!currentWallet) {
        return <div>wallet not found</div>;
    }

    const startDate =
        date?.[0] && dayjs(date[0])?.format("YYYY-MM-DD");
    const endDate =
        date?.[1] && dayjs(date[1])?.format("YYYY-MM-DD");

    const onPageChange = (page: number, size: number) => {
        setPageNumber(page);
        setPageSize(size);
    };

    const filter = () => {
        getDebitTransaction();
    };

    const filterData = (data) => {
        return data?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    typeof value === "string" &&
                    value.toLowerCase().includes(search.toLowerCase())
            )
        );
    };

    const handleReset = async () => {
        setStatus(null);
        setSelect(null);
    };

    const handleToggle = (item: string) => {
        setActiveToggle(item);
    };

    const text = `${currencyCode} transactions`;

    const DEBIT_COLUMN = tableColumns
    const getCreditTransaction = async () => {
        setLoading(true);
        try {
            const data = {
                toWallet: currentWallet?.id,
                pageNumber: pageNumber,
                pageSize: 10,
            };

            await getPayInTransactions(data).then((data) => {
                setTotalPayinData(data);
                setTableData(data);
                const payInData = data?.walletTransactions?.map((item) => ({
                    key: item.walletTransaction.id,
                    no: item.id,
                    transId:
                        formatLongString(item.walletTransaction.walletTnxId, 20) || "N/A",
                    type: formatValue(item.walletTransaction.userTransactionType),
                    rate: item.walletTransaction.rate || "N/A",
                    amount:
                        formatCurrencyAmount(
                            item.walletTransaction.toWallet.currency,
                            item.walletTransaction.amount
                        ) || "N/A",
                    narration: extractedString(item?.walletTransaction.narration),
                    fee: formatCurrencyAmount(
                        currencyCode,
                        item.walletTransaction.fee ?? 0
                    ),
                    status: item.walletTransaction.tnxStatus || "N/a",
                    date: dayjs(item.walletTransaction.createdAt || "N/A").format(
                        "DD.MM.YYYY ⎮ HH:mm"
                    ),
                }));

                setCreditData(filterData(payInData));

                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            console.error(
                "An error occurred while fetching payout transactions:",
                error
            );
        } finally {
            setLoading(false);
        }
    };

    const getDebitTransaction = async () => {
        setLoading(true);
        try {
            const commonData = {
                startDay: startDate,
                endDay: endDate,
                payoutStatus: select || status,
                pageNumber: pageNumber,
                fromWallet: currentWallet?.id,
                pageSize: 10,
            };

            let payload;

            if (currencyCode === "NGN") {
                payload = {
                    ...commonData,
                    toWallet: currentWallet?.id,
                };
            } else if (currencyCode === "ZAR") {
                payload = {
                    ...commonData,
                    toWallet: currentWallet?.id,
                };
            } else if (currencyCode === "USD") {
                payload = {
                    ...commonData,
                    toWallet: currentWallet?.id,
                };
            } else {
                payload = commonData;
            }

            const data = await getPayoutTransactions(
                payload,
                currencyCode,
                currentWallet?.id,
                businessId
            );

            if (currencyCode === "NGN") {
                const tableData = data?.foreignBankTransferRequests?.map((item) => ({
                    key: item.id,
                    beneficiary: item.accountHolderName || "N/A",
                    amount:
                        formatCurrencyCodeAmount(item?.sourceCurrency, item?.amount) ||
                        "N/A",
                    account: item?.accountNumber || "N/A",
                    status: item?.payoutStatus || "N/A",
                    fee: "N/A",
                    date: dayjs(item.updatedAt).format("DD.MM.YYYY - HH:mm"),
                    destinationCurrency: item?.destinationCurrency || "N/A",
                }));
                setDebitData(filterData(tableData));
            } else if (currencyCode === "ZAR") {
                const tableData = data.map((item) => ({
                    key: item.id,
                    beneficiary: item.accountName.length > 1 ? item.accountName : "N/A",
                    account: item.accountNumber || "N/A",
                    amount:
                        formatCurrencyAmount(item.wallet.currency, item.amount) || "N/A",
                    date: dayjs(item.createdAt).format("DD.MM.YYYY - HH:mm") || "N/A",
                    fee: "N/A",
                    status: item.payoutStatus || "N/A",
                }));
                setDebitData(tableData);
            } else if (currencyCode === "USD") {
                const walletData = tableData.walletTransactions.map(({withdrawDetails}) => {
                    if (!withdrawDetails) {
                        return null;
                    }

                    return {
                        key: withdrawDetails.id || "N/A",
                        beneficiary: capitalizeName(withdrawDetails.name) || "N/A",
                        amount:
                            formatCurrencyAmount(
                                withdrawDetails.currency ?? 0,
                                withdrawDetails.amount ?? 0
                            ) || "N/A",
                        account: withdrawDetails.accountNumber || "N/A",
                        transaction_type:
                            withdrawDetails.transactionType || "N/A",
                        date: withdrawDetails.createdAt
                            ? dayjs(withdrawDetails.createdAt).format("DD.MM.YYYY - HH:mm")
                            : "N/A",
                        fee:
                            formatCurrencyAmount(
                                withdrawDetails.currency ?? 0,
                                withdrawDetails.totalFee ?? 0
                            ) || "N/A",
                        status: withdrawDetails.payoutStatus || "N/A",
                    };
                });

                const formattedData = walletData.filter((item) => item !== null);

                setDebitData(filterData(formattedData));
            } else {
                const payOutData = data?.map((item) => ({
                    key: item.id,
                    beneficiary: item.accountName || "N/A",
                    amount:
                        formatCurrencyAmount(item.wallet.currency, item.amount) || "N/A",
                    account: item.accountNumber || "N/A",
                    status: item.payoutStatus || "N/A",
                    date: dayjs(item.createdAt).format("DD.MM.YYYY - HH:mm"),
                    fee: "N/A",
                }));
                setDebitData(filterData(payOutData));
            }

            setTableData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(
                "An error occurred while fetching payout transactions:",
                error
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (activeToggle === "Credit") {
            getCreditTransaction();

        }
        if (activeToggle === "Debit") {
            getDebitTransaction();

        }
    }, [currencyCode, pageNumber, startDate, endDate, select, status, search, activeToggle]);
    const handleDrawer = (row) => {
        setState((prev) => ({
            ...prev,
            openDrawer: true,
            data: row,
        }));
    };

    return (
        <StyledTransactionsTable width={width} >
            <Loader isLoading={loading}/>
            <h3>{width > 768 ? text : ''}</h3>

            {
                <>
                    <Tools
                        search={search}
                        setSearch={setSearch}
                        onStatusChange={(data) =>
                            setStatus(data)
                        }
                        onDateChange={(date) =>
                            setDate(date)
                        }
                        onFilterButtonClick={filter}
                        onResetButtonClick={handleReset}
                        onSelectChange={(data) =>
                            setSelect(data)
                        }
                        onCSVButtonClick={() =>
                            eportWalletTransaction(
                                tableData,
                                currencyCode,
                                activeToggle,
                            )
                        }
                        onXLSButtonClick={() =>
                            exporWalletXlX(
                                tableData,
                                currencyCode,
                                activeToggle,
                            )
                        }
                    />

                    <ToggleContainer>
                        <ToggleButton
                            toggleType={toggleType}
                            activeToggle={activeToggle}
                            setActiveToggle={
                                setActiveToggle
                            }
                            handleToggle={handleToggle}
                        />
                    </ToggleContainer>
                </>
            }

            {activeToggle === "Debit" && (
                <>
                    {width > 768 ? (
                        <CustomTable
                            extraEmptyChildren={
                                <EmptyBalanceDescription/>
                            }
                            columns={DEBIT_COLUMN}
                            dataSource={debitData}
                            currentPage={
                                tableData?.pageNumber
                            }
                            totalItemCount={
                                tableData?.totalCount
                            }
                            onPageChange={onPageChange}
                            handleRowClick={
                                currencyCode === "USD" &&
                                handleDrawer
                            }
                        />
                    ) : (
                        <MobileBody>
                            <CustomMobileTable
                                data={debitData}
                                WalletCard={WalletCard}
                                EmptyMobileCard={EmptyMobileCard}
                                currentPage={tableData?.pageNumber}
                                totalItemCount={tableData?.totalCount}
                                onPageChange={onPageChange}
                                pageSize={pageSize}
                                pageSizeOptions={[10, 20, 50]}
                            />
                        </MobileBody>
                    )}
                </>
            )}

            {activeToggle === "Credit" && (
                <>
                    {width > 768 ? (
                        <CustomTable
                            extraEmptyChildren={
                                <EmptyBalanceDescription/>
                            }
                            columns={payIntableColumns}
                            dataSource={creditData}
                            currentPage={pageNumber}
                            totalItemCount={
                                totalPayInData?.totalCount
                            }
                            onPageChange={onPageChange}
                        />
                    ) : (
                        <MobileBody>
                            <CustomMobileTable
                                data={creditData}
                                WalletCard={WalletCard}
                                EmptyMobileCard={EmptyMobileCard}
                                currentPage={pageNumber}
                                totalItemCount={totalPayInData?.totalCount}
                                onPageChange={onPageChange}
                                pageSize={pageSize}
                                pageSizeOptions={[10, 20, 50]}
                            />
                        </MobileBody>
                    )}
                </>
            )}

            <Drawer
                setState={setState}
                data={state.data}
            />
        </StyledTransactionsTable>
    );
};

export default Table;
