import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { usePostHog } from 'posthog-js/react'
import { v4 as uuidv4 } from "uuid";
import { sendFunds } from "../../../../../../../KlashaWire/wiresAPI";
import {
  Wrapper,
  DetailsBox,
  DetailsSectionHeader,
  DetailsContent,
  DetailsRow,
  Status,
} from "./style";
import CryptoJS from "crypto-js";
import { useAppDispatch, useAppSelector} from "../../../../../../../../app/hooks";
import PaymentSuccess from "../../../../../../../../assets/icons/success-new.svg";
import Failure from "../../../../../../../../assets/icons/error-icon.svg";
import Loader from "../../../../../../../../components/common/Loader";
import { nextVariants, removeLastCharacter } from "../../../../../../utils";
import { useParams } from "react-router-dom";
import { formatCurrencyAmount, formatNumber } from "../../../../../../../../utils/currency";
import WalletOTP from "../WalletOTP";
import { Button } from "../../../../../../../../components/common/Button";
import dayjs from "dayjs";
import CustomModal from "../../../../../../../../components/common/CustomModal";
import { sendOTP } from "../../../../../../../../api/usersAPI";
import { merchantBusinessKeys } from "../../../../../../../Settings/settingsSlice";
import { getAllWallets, sendFromWallet } from "../../../../../../api";
import { SuccessAlertContext } from "../../../../../../../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../../../../../../../context/NetworkErrorAlert";

type Preview = {
  receipientAmount?: any;
  exchangeRate?: number;
  totalAmount?: any;
  description?: string;
  beneficiary?: string;
  phone?: string;
  email?: string;
  accountNumber?: string;
  amount?: number;
  currency: string;
  bankCode?: string;
  bankName?: string;
  onGoBack: (data: number) => void;
  close: boolean;
  setClose: Dispatch<SetStateAction<boolean>>;
  activeCurrency: string;
  internationalAccountDetails: any;
  country?: string;
  scheme?: string;
  phoneNumber?:string
  recipientAddress?:string
  
};

const StepReview = ({
  receipientAmount,
  exchangeRate,
  totalAmount,
  description,
  beneficiary,
  accountNumber,
  amount,
  bankCode,
  bankName,
  activeCurrency,
  internationalAccountDetails,
  currency,
  scheme,
  country,
  phoneNumber,
  onGoBack,
  setClose,
  recipientAddress
}: Preview) => {
  const posthog = usePostHog();
  const [loading, setLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  const [erroMessage, setErrorMessage] = useState("");
  const { currencyCode } = useParams();
  const [encryptionKey, setEncryptionKey] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const { wallets } = useAppSelector(
    (state) => state.wallets,
  );
  const today = dayjs().format("DD.MM.YYYY"); 
  const { users } = useAppSelector((state) => state);
  const [verifyOTP, setVerifyOTP] = useState(false);

  const { userObj } = users || {};
  const { accountUser } = userObj || {};
  const { businessId } = useAppSelector(
    (state) => state.users,
);

const successAlertContext = useContext(
    SuccessAlertContext,
);


const { onShowAlert: onSuccess } =
    successAlertContext || {};
    const { onShowAlert} = useContext(
        NetworkErrorAlertContext,
      );


  const wallet = wallets?.find(
    (wallet) => wallet.currency === currencyCode,
  );



  const [taxRefId, setTaxRefId] = useState("");

  const extractName = (fullName) => {
    const names = fullName?.trim()?.split(" ");
    const firstName = names?.[0] ?? "";
    const lastName = names?.[1] ?? "";
    return { firstName, lastName };
  };

  const { firstName, lastName } = extractName(
    internationalAccountDetails?.accountName,
  );

  const TransferFunds = activeCurrency === "International";

  const encryptData = (data) => {
    const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        encryptionKey,
    ).toString();
    return encrypted;
};

useEffect(() => {
    if (businessId) {
        dispatch(merchantBusinessKeys(businessId))?.then(
            (data) => {                
                setPublicKey(data?.payload?.data?.publicKey);
                setEncryptionKey(data?.payload?.data?.encryptionKey);
            },
        );
    }
}, []);

  useEffect(() => {
    let txRefId = uuidv4();
    txRefId = `tranf-${txRefId}`;
    setTaxRefId(txRefId);
  }, []);

  const debitWallet = async () => {
    const formData = {
      amount: amount,
      country: removeLastCharacter(currencyCode),
      currency: currencyCode,
      bankCode: bankCode,
      bankName: bankName,
      accountNumber: accountNumber,
      accountName: beneficiary,
      requestId: taxRefId,
      description: description,
      type: '',
      recipientAddress: recipientAddress,
      mobileNumber: phoneNumber
    };

    if (currencyCode === 'ZAR') {
      formData.type = 'basa';
    } if(currencyCode === 'KES'){
        formData.type = 'Personal'
    }
    else {
      formData.type = 'mobile_money'; 
    }    
  
    const encryptedData = encryptData(formData);
    const payload = {
      message: encryptedData,
    };
  
    setLoading(true);
  
    try {
      await sendFromWallet(payload, publicKey);
      getAllWallets({businessId})
      onSuccess("Payment sent successfully");
      window.location.reload();
      setLoading(false);
    } catch (error) {   
        onShowAlert("", error?.message);             
      setLoading(false);
    }
  };
  
  

  const makePayment = async () => {
    const wireObj = {
      sourceCurrency: currencyCode,
      destinationCurrency: currencyCode,
      amount: Number(amount),
      requestId: taxRefId,
      accountHolderName: beneficiary,
      bankCode: bankCode,
      bankName: bankName,
      accountNumber: accountNumber,
      type: "corporate",
      email: accountUser?.email,
      fee: exchangeRate,
      narration: description,
      wallet: wallet?.id,
    };
    
    const internationalPayload = {
      sourceCurrency: currencyCode,
      destinationCurrency: currency,
      amount: amount,
      requestId: taxRefId,
      firstName: firstName,
      lastName: lastName,
      accountHolderName:
        internationalAccountDetails?.accountName,
      phone: accountUser?.username,
      accountNumber:
        internationalAccountDetails?.accountNumber,
      type: "corporate",
      email: accountUser?.email,
      country: country,
      paymentScheme: scheme,
      saveBeneficiary: true,
      fee: exchangeRate,
      narration: description,
      wallet: wallet?.id,
      sortCode: bankName,
    };

    setLoading(true);

    try {
      await sendFunds(
        TransferFunds ? internationalPayload : wireObj,
      );
      setOpenSuccess(true);

      posthog?.capture("otp_wallet_send_b2b", { status: "success", currency: currencyCode})
      setTimeout(() => {
        setOpenSuccess(false);
        location.reload();
        setClose(false);
      }, 3000);
    } catch (error) {
      setErrorMessage(error);
      setError(true);
      setLoading(false);
      posthog?.capture("otp_wallet_send_b2b", { status: 'failure', failure_reason: error, currency: currencyCode})
    }
  };

  const handleSendOTP = async (user: { email: string }) => {
    try {
      setLoading(true);

      await sendOTP(user)
      setVerifyOTP(true);
      posthog?.capture("review_wallet_send_b2b", { status: 'success', currency: currencyCode})
      
      setLoading(false);
    } catch (error) {
      setLoading(false);

      const errorObj = error?.response?.data;
      let message: string = '';
      
      if(typeof errorObj?.error === "string") {
        message = errorObj.error;
      } else if(typeof errorObj?.message === "string"){
        message = errorObj.message;
      } else if(typeof errorObj === "string"){
        message = errorObj
      } else {
        message = "Failed to send OTP"
      }

      posthog?.capture("review_wallet_send_b2b", { status: 'failure', failure_reason: message, currency: currencyCode})
    }
  };
  
  return (
    <Wrapper
      variants={nextVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
    <Loader isLoading={loading} />

    <DetailsBox>
      <div>
          <DetailsSectionHeader>
            <h1>Transaction details</h1>
            <span onClick={() => onGoBack(0)}>Edit</span>
          </DetailsSectionHeader>
          <DetailsContent>
            <DetailsRow>
              <p>
              Amount
              </p>
              <span>
                {formatCurrencyAmount(currencyCode, receipientAmount)}
              </span>
            </DetailsRow>
            <DetailsRow>
              <p>Processing Fee</p>
              <span>
                {formatCurrencyAmount(currencyCode, exchangeRate)}
              </span>
            </DetailsRow>
            {description && (
            <DetailsRow>
            <p>Description</p>
             <span>{description}</span>
             </DetailsRow>
                )}

            <DetailsRow>
              <p>Total amount</p>
              <span>{totalAmount}</span>
            </DetailsRow>
            <DetailsRow>
              <p>Date</p>
              <span>{today}</span>
            </DetailsRow>
          </DetailsContent>
      </div>

      <div>
          <DetailsSectionHeader>
            <h1>Beneficiary details</h1>
            <span onClick={() => onGoBack(1)}>Edit</span>
          </DetailsSectionHeader>
          <DetailsContent>
            {currencyCode !== "GHS" && (
                <DetailsRow>
                <p>Beneficiary</p>
                <span>{beneficiary}</span>
              </DetailsRow>)}

            <DetailsRow>
              <p>{currencyCode === "KES" ? "Account/Momo number" : "Account number"}</p>
              <span>{accountNumber}</span>
            </DetailsRow>

            {TransferFunds ? null : (
              <DetailsRow>
                <p>{currencyCode === "KES" ? "Select bank/Wallet type" : "Bank name"}</p>
                <span>{bankName}</span>
              </DetailsRow>
            )}
          </DetailsContent>
      </div>
    </DetailsBox>
      
    <Button
      label="Continue"
      fontSize="14px"
      height="48px"
      onClick={() => handleSendOTP(accountUser?.email)}
    />

    {verifyOTP && (
      <WalletOTP
        setVerifyOTP={setVerifyOTP}
        makePayment={currencyCode === "NGN" ? makePayment : debitWallet }
      />
    )}

    <CustomModal 
       isModalVisible={openSuccess}
       onClose={() => setOpenSuccess(false)}
       width="480px"
    >
      <Status>
        <img src={PaymentSuccess} alt='success-logo'/>
        <div>
          <h3>Your payment was successful</h3>
          <p>
            Redirecting you back to the initial page...
          </p>
        </div>
      </Status>
    </CustomModal>

    <CustomModal 
       isModalVisible={error}
       onClose={() => setError(false)}
       width="480px"
    >
      <Status>
        <img src={Failure} alt='failed-logo'/>
        <div>
          <h3>Your payment was unsuccessful...</h3>
          <p>
            {erroMessage}
          </p>
        </div>
      </Status>
    </CustomModal>
    </Wrapper>
  );
};

export default StepReview;
